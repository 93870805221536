<template>
  <v-container dark class="container pa-0 ma-0" fluid>
    <v-flex v-if="!$vuetify.breakpoint.smAndDown" xs12 md6>
      <div
        style="display: flex; width: 100%; height: 100vh; background-color: #fff;"
      >
        <v-img width="100%" src="img/login_image3.jpg" alt="">
          <div
            class="expande-horizontal"
            style="height: 100vh; background-size: cover; background-color: rgba(0,0,0,0.2);"
          >
            <div class="expande-horizontal pa-6">
              <v-spacer></v-spacer>
              <!-- <img height="60" class="ma-6" src="img/logo.png" alt="" /> -->
            </div>
          </div>
        </v-img>
      </div>
    </v-flex>
    <v-flex xs12 md6>
      <div
        style="height: 100vh; background: #fff;"
        class="expande-horizontal centraliza column"
      >
        <FormLogin />
      </div>
    </v-flex>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import FormLogin from "./components/FormLogin";

export default {
  components: {
    ...mapGetters(["loggedUser"]),
    FormLogin
  },
  created() {
    if (this.loggedUser) {
      this.$router.push("/dashboard");
    }
    if (cordova) {
      if (cordova.platformId == "android") {
        StatusBar.backgroundColorByHexString(this.$theme.secondary);
      }
    }
  },
};
</script>

<style scoped>
.container {
  display: flex;
  align-items: flex-start/;
  flex-direction: row;
  background-color: #fff;
  height: 100%;
  padding-bottom: 0;
  margin-bottom: 0;
}

.logo {
  align-self: stretch;
}

.img-checkout {
  align-self: center;
}
</style>
